import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { useTranslation } from "react-i18next"
import {graphql} from 'gatsby'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import queryString from 'query-string';

const PageTerms = props => {
  
  
  const { body, parent,validAt } = props.data.allStrapiPage.nodes[0];
  const { t } = useTranslation();
  const query = queryString.parse(props.location.search);
  // console.log(query);

  return query?.simple !=='true' ? (
    <Layout {...props}>
      <Seo title={parent.title} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header={parent.title}
        />
      </Stack>
      <Stack>
        <Main>
          <ReactMarkdown children={body.data} remarkPlugins={[remarkGfm]} />
          {t('This policy is effective from_', {'date' : validAt})}
        </Main>
      </Stack>
    </Layout>
  ) : (<ReactMarkdown children={body.data} remarkPlugins={[remarkGfm]} />)
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    
  allStrapiPage(
    filter: {locale: {eq: $language}, parent: {internal: {content: {regex: "/paid_terms/"}}}}
    sort: {order: DESC, fields: validAt}
    limit: 1
  ) {
    nodes {
      body {
        data
      }
      validAt
      parent {
        ... on STRAPI_POLICY {
          
          slug
          title
        }
      }
    }
  }

  }`  

export default PageTerms
